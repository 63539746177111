import React from 'react';
import { Link } from 'gatsby';
import StaticLayout from 'components/StaticLayout';
import './404.module.css';

const NotFoundPage = () => (
  <StaticLayout>
    <div styleName="wrapper">
      <div>&nbsp;</div>
      <h1>
        <span>Такой страницы мы не нашли, вернемся на </span>
        <Link to="/">главную</Link>
        <span>?</span>
      </h1>
    </div>
  </StaticLayout>
);

export default NotFoundPage;
